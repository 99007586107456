<script>
import Detail from "./detail";
import Pay from "./payConfirm.vue";

export default {
  props: {
    currentEmpresa: { type: Object, required: true },
    detalhe_boleto: { type: Object },
    listBancos: { type: Array, required: true },
    pessoas: { type: Array },
    hide: { type: Boolean, required: true },
  },
  components: { Detail, Pay },
  data() {
    return {
      vet_contas_rec:[],
      select_todos_checks:null,
      boleto: {},
      modal_pay: false,
      objetoPagamento: null,
      titleBody: "Contas a Receber",
      currentBanco: undefined,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        {
          label: "Check",
          key: "check",
          sortable: true,
          tdClass: "text-left",
          thClass: "text-left",
        },
        {
          label: "Número",
          key: "num_doc",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Cliente",
          key: "cliente_nome",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        // { label: "Historico Padrão", key: "historico_cod", sortable: true, tdClass: 'text-left', thClass: 'text-center' },
        {
          label: "Valor",
          key: "valor",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Emissão",
          key: "emissao",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        // { label: "Dt Pagamento", key: "fatura", sortable: true, tdClass: 'text-left', thClass: 'text-center' },

        {
          label: "Status",
          key: "status",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        //{ label: "ID_Empresa", key: "empresa_id", sortable: false, thClass: 'text-center' },
        //{ label: 'Preço', key: 'preco', sortable: false, tdClass: 'text-right', thClass: 'text-center',}
        //   formatter: "formatCurrency"},
        {
          label: "Ações",
          key: "acoes",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.listBancos.length ? this.listBancos.length : 0;
    },
    clonefoot() {
      return this.listBancos.length || this.listBancos.length > 10
        ? true
        : false;
    },
  },
  mounted() {
    this.$emit("newTitle", this.titleBody);
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    envia_mult_remessa_req(){
      console.log("fazer um post aqui")
      console.log(this.vet_contas_rec)
      let objeto_remessa = {
        vet:[...this.vet_contas_rec]
      }
      console.log(objeto_remessa)
      this.$emit('emvia_vet_remessa',objeto_remessa)
    },
    seleciona_checkbox_todos(event){
      console.log("Seleciona Todos")
      console.log(event)
    },
    seleciona_checkbox(event,objeto,index){
         
       console.log(event)
       console.log(objeto)
       console.log(index)
      let obejto_conta_receber = objeto
       if (event == true) {
        this.vet_contas_rec.push({ ...obejto_conta_receber });
        console.log(this.vet_contas_rec);
        console.log("entrou no true")
      } else {
        this.vet_contas_rec = this.vet_contas_rec.filter(
          (onjeto) => onjeto.id !== obejto_conta_receber.id
        );
        console.log("entrou no false")
        console.log(this.vet_contas_rec);
      }
    },
    // eslint-disable-next-line no-unused-vars
    formatCurrency(value, key, item) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    setRemessa(objeto){
         console.log("estou no Remessa")
        console.log(objeto);
        this.$emit('getRemessaTxt');
    },
    setRemessaPdf(objeto){
        console.log("estou no Remessa")
        console.log(objeto);
        this.$emit('getRemessa');
    },
    setRemessaBradesco(objeto){
       console.log(objeto)
       console.log("Remessa Bradesco")
       this.$emit('getRemessaBradesco', objeto);
    },
    setRemessaBradescoExt(objeto){
       console.log(objeto)
       console.log("Bradesco Extendida")
      this.$emit('getRemessaBradescoExtendida');
    },
    setRetornoBradesco(objeto){
       console.log("Retorno Bradesco")
       console.log(objeto)
       this.$emit('getRemessaBradescoRetorno')
    },
    setRemessaBradescoPdf(objeto){ 
       console.log("Set pdfBradesco")
       console.log(objeto)
       this.$emit('getRemessaBradescoPdf',objeto);
    },
    setRegBoleto(objeto){
       console.log("Registrnado Boleto para Posterior Pagamento")
       console.log(objeto)
       this.$emit('post_registra_boleto', objeto);
    },
    setDetalhaBoleto(objeto) {
      console.log("Estou detalhando Boleto");
      console.log(objeto);
      // this.$emit('getDetalhaBoleto');
    },
    confirmar_detalhe_boleto() {
      this.$emit("getDetalhaBoleto", this.boleto);
    },
    gera_boleto_detalhado() {
      console.log("Mosrando Objeto de Boleto Detalhddo");
      let objeto = this.detalhe_boleto.response;
      objeto.nossoNumero = this.boleto.numero_boleto;
      console.log(objeto);
      this.$emit("gera_boleto_detalhado", this.detalhe_boleto.response);
    },
    setBoleto(Objeto) {
      console.log("Gerando Boletos");
      console.log(Objeto);
      this.$emit("get_token");
    },
    confirma_pag() {
      this.objetoPagamento.status = 1;
      this.$emit("doPut", this.objetoPagamento);
    },
    setPixBoleto(objeto){
       console.log(objeto)
       this.$emit('post_pixBoleto',objeto)
    },
    setCanPixBoleto(objeto){
       console.log(objeto)
       this.$emit('post_can_pixBoleto',objeto)
    },
    setPago(objeto) {
      this.modal_pay = true;
      console.log(objeto.status);
      this.objetoPagamento = { ...objeto };
    },
    back_pay() {
      console.log("esstou no baack py");
      this.modal_pay = false;
    },

    setBanco(value) {
      this.currentBanco = value;
      this.$refs["modal-banco"].show();
    },
    setEdit(value) {
      this.setBanco(value);
      this.$emit("edit", value);
    },
    setDelete(value) {
      this.$emit("doDelete", value);
    },
  },
};
</script>

<template>
  <div class="card-body" v-if="hide">
    <div class="row mt-4">
      <!-- Start Limit -->
      <div class="col-sm-12 col-md-6">
        <div id="produto-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Exibir&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;registros
          </label>
        </div>
      </div>
      <!-- End Limit -->
      <!-- Start Search -->
      <div class="col-sm-12 col-md-6">
        <div id="produto-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Pesquisar:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Pesquisar..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Start Table -->
    <div class="row mt-4 mb-2 d-flex justify-content-center align-items-center" v-if="vet_contas_rec.length >= 1">
         <div class="col-md-12 text-center">
           <button class="btn btn-success" @click.prevent="envia_mult_remessa_req()">Download Remessa Multipla</button>
         </div>
    </div>
    <div class="row mt-4">
      <!--  responsive="sm" -->
      <div v-if="hide" class="table">
        <b-table
          :items="listBancos"
          :fields="fields"
         
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :hover="true"
          :foot-clone="clonefoot"
        >
        <template #head(check)="data">
            <b-form-checkbox
              v-model="select_todos_checks"
              @change="seleciona_checkbox_todos($event)"
            >
              {{ data.label == "Check" ? "Todos" : "Nada" }}
            </b-form-checkbox>
          </template>
          <template #cell(check)="row">
            <b-form-checkbox
              v-model="row.item.check"
              @change="seleciona_checkbox($event,row.item,row.index)"
            >
            </b-form-checkbox>
          </template>
          <template #cell(valor)="row">
            <span class="badge badge-info">{{
              row.item.valor
            }}</span>
          </template>
          <template #cell(num_doc)="row">
            {{row.item.num_documento }}
          </template>
          <template #cell(status)="row">
            <span
              :class="
                row.item.status == 0
                  ? 'badge badge-danger'
                  : 'badge badge-success'
              "
              >{{ row.item.status == 0 ? "Não Pago" : "Pago" }}</span
            >
          </template>
          <template #cell(acoes)="row">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-dropdown v-bind:id="'dropdown-' + row.item.id" class="m-md-2" right
              text="Right align">
              <template #button-content>
                <i data-v-6289eca4="" class="bx ri-menu-line"></i>
              </template>
              <b-dropdown-item v-b-modal.modal-banco @click="setBanco(row.item)"
                ><i class="bx ri-eraser-fill"></i> Visualizar</b-dropdown-item
              >
              <b-dropdown-item  @click="setRemessaBradesco(row.item)"
                ><i class="bx ri-eraser-fill"></i>Arquivo Remessa Bradesco</b-dropdown-item
              >
              <b-dropdown-item  @click="setRemessaBradescoExt(row.item)"
                ><i class="bx ri-eraser-fill"></i>Arquivo Remessa Bradesco Extendida</b-dropdown-item
              >
              <b-dropdown-item  @click="setRetornoBradesco(row.item)"
                ><i class="bx ri-eraser-fill"></i>Retorno Bradesco</b-dropdown-item
              >
              <b-dropdown-item  @click="setRemessaBradescoPdf(row.item)"
                ><i class="bx ri-eraser-fill"></i>Boleto Bradesco</b-dropdown-item
              >
              <b-dropdown-item  @click="setRemessa(row.item)"
                ><i class="bx ri-eraser-fill"></i>Arquivo Remessa TXT</b-dropdown-item
              >
              <b-dropdown-item  @click="setRemessaPdf(row.item)"
                ><i class="bx ri-eraser-fill"></i>Arquivo Remessa PDF</b-dropdown-item
              >
              <b-dropdown-item  @click="setPixBoleto(row.item)"
                ><i class="bx ri-eraser-fill"></i> Consutlar Pix Boleto</b-dropdown-item
              >
              <b-dropdown-item  @click="setCanPixBoleto(row.item)"
                ><i class="bx ri-eraser-fill"></i> Cancelar Pix Boleto</b-dropdown-item
              >
              <b-dropdown-item @click="setBoleto(row.item)"
                ><i class="bx ri-eraser-fill"></i>Gerar Boleto</b-dropdown-item
              >
              <b-dropdown-item @click="setRegBoleto(row.item)"
                ><i class="bx ri-eraser-fill"></i>Resgtrar um Boleto Para esse conta</b-dropdown-item
              >
              <b-dropdown-item
                v-b-modal.modal_boleto_detalhe
                @click="setDetalhaBoleto(row.item)"
                ><i class="bx ri-eraser-fill"></i>Detalha
                Boleto</b-dropdown-item
              >
              <b-dropdown-item
                v-if="row.item.status == 0"
                @click="setPago(row.item)"
                ><i class="bx ri-eraser-fill"></i> Pagar</b-dropdown-item
              >
              <b-dropdown-item
                v-if="row.item.status == 0"
                @click="setEdit(row.item)"
                ><i class="bx ri-edit-2-line"></i> Alterar</b-dropdown-item
              >
              <b-dropdown-item
                v-if="row.item.status == 0"
                @click="setDelete(row.item)"
                ><i class="bx ri-eraser-fill"></i> Excluir</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
      </div>
    </div>
    <!-- End Table -->
    <!-- Start Pagination -->
    <div v-if="hide" class="row mt-4">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Pagination -->
    <!-- Start Modal Produto -->
    <b-modal
      v-if="hide"
      hide-footer
      id="modal-banco"
      ref="modal-banco"
      :title="'Detalhes do Banco'"
      title-class="font-18"
    >
      <Detail :banco="currentBanco" :pessoas="pessoas" />
    </b-modal>

    <b-modal
      @ok="confirma_pag()"
      v-model="modal_pay"
      :title="'Confirmar Pagemento'"
      title-class="font-18"
      @hidden="back_pay()"
    >
      <Pay />
    </b-modal>

    <b-modal
      @ok="confirma_pag()"
      id="modal_boleto_detalhe"
      :title="'Detalhe Boleto'"
      title-class="font-18"
      hide-footer
    >
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <Label>Número do título de cobrança</Label>
          <input
            type="text"
            class="form-control text-left"
            v-model="boleto.numero_boleto"
          />
        </div>
        <div class="col-md-6 text-center mt-4">
          <button
            class="btn btn-success"
            @click.prevent="confirmar_detalhe_boleto()"
          >
            Pesquisar
          </button>
        </div>
        <div v-if="detalhe_boleto" class="col-md-6 text-center mt-4">
          <button
            class="btn btn-success"
            @click.prevent="gera_boleto_detalhado()"
          >
            Baixar Boleto
          </button>
        </div>
      </div>
      <div class="row d-flex justify-content-center align-items-center mt-4" v-if="detalhe_boleto">
        <div class="col-md-12 text-center">
          {{ "codigoAceiteTituloCobranca" }} ->
          {{ detalhe_boleto.response.codigoAceiteTituloCobranca }}
          <br />
          {{ "codigoCanalPagamento" }} ->
          {{ detalhe_boleto.response.codigoCanalPagamento }}
          <br />
          {{ "codigoDescontoTitulo" }} ->
          {{ detalhe_boleto.response.codigoDescontoTitulo }}
          <br />
          {{ "codigoEstadoTituloCobranca" }} ->
          {{ detalhe_boleto.response.codigoEstadoTituloCobranca }}
          <br />
          {{ "codigoIndicadorEconomico" }} ->
          {{ detalhe_boleto.response.codigoIndicadorEconomico }}
          <br />
          {{ "codigoIndicadorEconomicoUtilizadoInadimplencia" }} ->
          {{
            detalhe_boleto.response
              .codigoIndicadorEconomicoUtilizadoInadimplencia
          }}
          <br />
          {{ "codigoLinhaDigitavel" }} ->
          {{ detalhe_boleto.response.codigoLinhaDigitavel }}
          <br />
          {{ "codigoModalidadeTitulo" }} ->
          {{ detalhe_boleto.response.codigoModalidadeTitulo }}
          <br />
          {{ "codigoNaturezaRecebimento" }} ->
          {{ detalhe_boleto.response.codigoNaturezaRecebimento }}
          <br />
          {{ "codigoOcorrenciaCartorio" }} ->
          {{ detalhe_boleto.response.codigoOcorrenciaCartorio }}
          <br />
          {{ "codigoPrefixoDependenciaCobrador" }} ->
          {{ detalhe_boleto.response.codigoPrefixoDependenciaCobrador }}
          <br />
          {{ "codigoPrefixoDependenciaRecebedor" }} ->
          {{ detalhe_boleto.response.codigoPrefixoDependenciaRecebedor }}
          <br />
          {{ "codigoResponsavelAtualizacao" }} ->
          {{ detalhe_boleto.response.codigoResponsavelAtualizacao }}
          <br />
          {{ "codigoSegundoDescontoTitulo" }} ->
          {{ detalhe_boleto.response.codigoSegundoDescontoTitulo }}
          <br />
          {{ "codigoTerceiroDescontoTitulo" }} ->
          {{ detalhe_boleto.response.codigoTerceiroDescontoTitulo }}
          <br />
          {{ "codigoTerceiroDescontoTitulo" }} ->
          {{ detalhe_boleto.response.codigoTerceiroDescontoTitulo }}
          <br />
          {{ "codigoTipoBaixaTitulo" }} ->
          {{ detalhe_boleto.response.codigoTipoBaixaTitulo }}
          <br />
          {{ "codigoTipoInscricaoSacado" }} ->
          {{ detalhe_boleto.response.codigoTipoInscricaoSacado }}
          <br />
          {{ "codigoTipoInscricaoSacador" }} ->
          {{ detalhe_boleto.response.codigoTipoInscricaoSacador }}
          <br />
          {{ "codigoTipoJuroMora" }} ->
          {{ detalhe_boleto.response.codigoTipoJuroMora }}
          <br />
          {{ "codigoTipoLiquidacao" }} ->
          {{ detalhe_boleto.response.codigoTipoLiquidacao }}
          <br />
          {{ "codigoTipoMulta" }} ->
          {{ detalhe_boleto.response.codigoTipoMulta }}
          <br />
          {{ "codigoTipoMulta" }} ->
          {{ detalhe_boleto.response.codigoTipoMulta }}
          <br>
          <p>
            {{ "codigoTipoTituloCobranca" }} ->
            {{ detalhe_boleto.response.codigoTipoTituloCobranca }}
          </p>
          <br>
          <p>
            {{ "dataBaixaAutomaticoTitulo" }} ->
            {{ detalhe_boleto.response.dataBaixaAutomaticoTitulo }}
          </p>
          <br>
          <p>
            {{ "dataCreditoLiquidacao" }} ->
            {{ detalhe_boleto.response.dataCreditoLiquidacao }}
          </p>
          <br>
          <p>
            {{ "dataDescontoTitulo" }} ->
            {{ detalhe_boleto.response.dataDescontoTitulo }}
          </p>
          <br>
          <p>
            {{ "dataEmissaoTituloCobranca" }} ->
            {{ detalhe_boleto.response.dataEmissaoTituloCobranca }}
          </p>
          <br>
          <p>
            {{ "dataLimiteRecebimentoTitulo" }} ->
            {{ detalhe_boleto.response.dataLimiteRecebimentoTitulo }}
          </p>
          <br>
          <p>
            {{ "dataMultaTitulo" }} ->
            {{ detalhe_boleto.response.dataMultaTitulo }}
          </p>
          <br>
          <p>
            {{ "dataProtestoTituloCobranca" }} ->
            {{ detalhe_boleto.response.dataProtestoTituloCobranca }}
          </p>
          <br>
          <p>
            {{ "dataRecebimentoTitulo" }} ->
            {{ detalhe_boleto.response.dataRecebimentoTitulo }}
          </p>
          <br>
          <p>
            {{ "dataRegistroTituloCobranca" }} ->
            {{ detalhe_boleto.response.dataRegistroTituloCobranca }}
          </p>
          <br>
          <p>
            {{ "dataSegundoDescontoTitulo" }} ->
            {{ detalhe_boleto.response.dataSegundoDescontoTitulo }}
          </p>
          <br>
          <p>
            {{ "dataTerceiroDescontoTitulo" }} ->
            {{ detalhe_boleto.response.dataTerceiroDescontoTitulo }}
          </p>
          <br>
          <p>
            {{ "dataVencimentoTituloCobranca" }} ->
            {{ detalhe_boleto.response.dataVencimentoTituloCobranca }}
          </p>
          <br>
          <p>
            {{ "indicadorCobrancaPartilhadoTitulo" }} ->
            {{ detalhe_boleto.response.indicadorCobrancaPartilhadoTitulo }}
          </p>
          <br>
          <p>
            {{ "indicadorPermissaoRecebimentoParcial" }} ->
            {{ detalhe_boleto.response.indicadorPermissaoRecebimentoParcial }}
          </p>
          <br>
          <p>
            {{ "nomeBairroSacadoCobranca" }} ->
            {{ detalhe_boleto.response.nomeBairroSacadoCobranca }}
          </p>
          <br>
          <p>
            {{ "nomeMunicipioSacadoCobranca" }} ->
            {{ detalhe_boleto.response.nomeMunicipioSacadoCobranca }}
          </p>
          <br>
          <p>
            {{ "nomeSacadoCobranca" }} ->
            {{ detalhe_boleto.response.nomeSacadoCobranca }}
          </p>
          <br>
          <p>
            {{ "nomeSacadorAvalistaTitulo" }} ->
            {{ detalhe_boleto.response.nomeSacadorAvalistaTitulo }}
          </p>
          <br>
          <p>
            {{ "numeroCarteiraCobranca" }} ->
            {{ detalhe_boleto.response.numeroCarteiraCobranca }}
          </p>
          <br>
          <p>
            {{ "numeroCepSacadoCobranca" }} ->
            {{ detalhe_boleto.response.numeroCepSacadoCobranca }}
          </p>
          <br>
          <p>
            {{ "numeroContratoCobranca" }} ->
            {{ detalhe_boleto.response.numeroContratoCobranca }}
          </p>
          <br>
          <p>
            {{ "numeroIdentidadeSacadoTituloCobranca" }} ->
            {{ detalhe_boleto.response.numeroIdentidadeSacadoTituloCobranca }}
          </p>
          <br>
          <p>
            {{ "numeroInscricaoSacadoCobranca" }} ->
            {{ detalhe_boleto.response.numeroInscricaoSacadoCobranca }}
          </p>
          <br>
          <p>
            {{ "numeroInscricaoSacadorAvalista" }} ->
            {{ detalhe_boleto.response.numeroInscricaoSacadorAvalista }}
          </p>
          <br>
          <p>
            {{ "numeroTituloCedenteCobranca" }} ->
            {{ detalhe_boleto.response.numeroTituloCedenteCobranca }}
          </p>
          <br>
          <p>
            {{ "numeroVariacaoCarteiraCobranca" }} ->
            {{ detalhe_boleto.response.numeroVariacaoCarteiraCobranca }}
          </p>
          <br>
          <p>
            {{ "percentualDescontoTitulo" }} ->
            {{ detalhe_boleto.response.percentualDescontoTitulo }}
          </p>
          <br>
          <p>
            {{ "percentualImpostoSobreOprFinanceirasTituloCobranca" }} ->
            {{
              detalhe_boleto.response
                .percentualImpostoSobreOprFinanceirasTituloCobranca
            }}
          </p>
          <br>
          <p>
            {{ "percentualJuroMoraTitulo" }} ->
            {{ detalhe_boleto.response.percentualJuroMoraTitulo }}
          </p>
          <br>
          <p>
            {{ "percentualMultaTitulo" }} ->
            {{ detalhe_boleto.response.percentualMultaTitulo }}
          </p>
          <br>
          <p>
            {{ "percentualSegundoDescontoTitulo" }} ->
            {{ detalhe_boleto.response.percentualSegundoDescontoTitulo }}
          </p>
          <br>
          <p>
            {{ "percentualTerceiroDescontoTitulo" }} ->
            {{ detalhe_boleto.response.percentualTerceiroDescontoTitulo }}
          </p>
          <br>
          <p>
            {{ "quantidadeDiaPrazoLimiteRecebimento" }} ->
            {{ detalhe_boleto.response.quantidadeDiaPrazoLimiteRecebimento }}
          </p>
          <br>
          <p>
            {{ "quantidadeDiaProtesto" }} ->
            {{ detalhe_boleto.response.quantidadeDiaProtesto }}
          </p>
          <br>
          <p>
            {{ "quantidadeParcelaTituloCobranca" }} ->
            {{ detalhe_boleto.response.quantidadeParcelaTituloCobranca }}
          </p>
          <br>
          <p>
            {{ "siglaUnidadeFederacaoSacadoCobranca" }} ->
            {{ detalhe_boleto.response.siglaUnidadeFederacaoSacadoCobranca }}
          </p>
          <br>
          <p>
            {{ "textoCampoUtilizacaoCedente" }} ->
            {{ detalhe_boleto.response.textoCampoUtilizacaoCedente }}
          </p>
          <br>
          <p>
            {{ "textoCodigoBarrasTituloCobranca" }} ->
            {{ detalhe_boleto.response.textoCodigoBarrasTituloCobranca }}
          </p>
          <br>
          <p>
            {{ "textoEmailPagador" }} ->
            {{ detalhe_boleto.response.textoEmailPagador }}
          </p>
          <br>
          <p>
            {{ "textoEnderecoSacadoCobranca" }} ->
            {{ detalhe_boleto.response.textoEnderecoSacadoCobranca }}
          </p>
          <br>
          <p>
            {{ "textoMensagemBloquetoTitulo" }} ->
            {{ detalhe_boleto.response.textoMensagemBloquetoTitulo }}
          </p>
          <br>
          <p>
            {{ "valorAbatimentoTituloCobranca" }} ->
            {{ detalhe_boleto.response.valorAbatimentoTituloCobranca }}
          </p>
          <br>
          <p>
            {{ "valorAbatimentoTotal" }} ->
            {{ detalhe_boleto.response.valorAbatimentoTotal }}
          </p>
          <br>
          <p>
            {{ "valorAtualTituloCobranca" }} ->
            {{ detalhe_boleto.response.valorAtualTituloCobranca }}
          </p>
          <br>
          <p>
            {{ "valorCreditoCedente" }} ->
            {{ detalhe_boleto.response.valorCreditoCedente }}
          </p>
          <br>
          <p>
            {{ "valorDescontoTitulo" }} ->
            {{ detalhe_boleto.response.valorDescontoTitulo }}
          </p>
          <br>
          <p>
            {{ "valorDescontoUtilizado" }} ->
            {{ detalhe_boleto.response.valorDescontoUtilizado }}
          </p>
          <br>
          <p>
            {{ "valorImpostoSobreOprFinanceirasRecebidoTitulo" }} ->
            {{
              detalhe_boleto.response
                .valorImpostoSobreOprFinanceirasRecebidoTitulo
            }}
          </p>
          <br>
          <p>
            {{ "valorImpostoSobreOprFinanceirasTituloCobranca" }} ->
            {{
              detalhe_boleto.response
                .valorImpostoSobreOprFinanceirasTituloCobranca
            }}
          </p>
          <br>
          <p>
            {{ "valorJuroMoraRecebido" }} ->
            {{ detalhe_boleto.response.valorJuroMoraRecebido }}
          </p>
          <br>
          <p>
            {{ "valorJuroMoraTitulo" }} ->
            {{ detalhe_boleto.response.valorJuroMoraTitulo }}
          </p>
          <br>
          <p>
            {{ "valorMoedaAbatimentoTitulo" }} ->
            {{ detalhe_boleto.response.valorMoedaAbatimentoTitulo }}
          </p>
          <br>
          <p>
            {{ "valorMoedaTituloCobranca" }} ->
            {{ detalhe_boleto.response.valorMoedaTituloCobranca }}
          </p>
          <br>
          <p>
            {{ "valorMultaRecebido" }} ->
            {{ detalhe_boleto.response.valorMultaRecebido }}
          </p>
          <br>
          <p>
            {{ "valorMultaTituloCobranca" }} ->
            {{ detalhe_boleto.response.valorMultaTituloCobranca }}
          </p>
          <br>
          <p>
            {{ "valorOriginalTituloCobranca" }} ->
            {{ detalhe_boleto.response.valorOriginalTituloCobranca }}
          </p>
          <br>
          <p>
            {{ "valorOutroRecebido" }} ->
            {{ detalhe_boleto.response.valorOutroRecebido }}
          </p>
          <br>
          <p>
            {{ "valorPagamentoParcialTitulo" }} ->
            {{ detalhe_boleto.response.valorPagamentoParcialTitulo }}
          </p>
          <br>
          <p>
            {{ "valorPagoSacado" }} ->
            {{ detalhe_boleto.response.valorPagoSacado }}
          </p>
          <br>
          <p>
            {{ "valorReajuste" }} -> {{ detalhe_boleto.response.valorReajuste }}
          </p>
          <br>
          <p>
            {{ "valorSegundoDescontoTitulo" }} ->
            {{ detalhe_boleto.response.valorSegundoDescontoTitulo }}
          </p>
          <br>
          <p>
            {{ "valorTerceiroDescontoTitulo" }} ->
            {{ detalhe_boleto.response.valorTerceiroDescontoTitulo }}
          </p>
        </div>
      </div>
    </b-modal>
    <!-- End Modal Produto -->
  </div>
</template>