<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <b-table
          bordered
          hover
          :items="files_array"
          id="doc-itens"
          :fields="fields"
        >
        </b-table>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <button class="btn btn-info mr-2" @click.prevent="upload_remessa()">
          Upload
        </button>
        <button class="btn btn-success" @click.prevent="ler()">ler</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <input
          @change="handleFileChange"
          type="file"
          id="fileInput"
          style="display: none"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../../../helpers/easyindustriaapi/config";
export default {
  props: {
    showModal: { type: Boolean },
  },
  data() {
    return {
      formData:null,
      view_modal: false,
      files_array: [],
      fields: [
        // {
        //   label: "Selecione",
        //   key: "check",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "Nome",
          key: "name",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Tamanho",
          key: "size",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Data",
          key: "lastModifiedDate",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
    };
  },
  created() {
    this.view_modal = this.showModal;
  },

  methods: {
    back() {},
    upload_remessa() {
      document.getElementById("fileInput").click();
    },
    handleFileChange(event) {
      // não era aqui para fazer esse logica é na função de enviar
      const file = Array.from(event.target.files);
      this.array_files_sem_check = [...file];
      this.files_array = [...file];
      console.log("estou no hanfle mostrando os files");
      console.log(this.files_array);
      //   console.log(this.files_array[0].textContent);
      const formData = new FormData();
      this.files_array.forEach((file) => {
        formData.append("files[]", file);
      });
      this.formData = formData
      console.log(formData)
     this.uploadFile(formData)

    },
    async uploadFile(formData) {
      try {
        const response = await http.post("/Remessa/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          this.makeToast("success","Upload concluído")
          console.log("Upload concluído:", response.data);
        } else {
          this.makeToast("danger","Erro no upload. Status")
          console.error("Erro no upload. Status:", response.status);
        }
      } catch (error) {
        this.makeToast("danger","Erro ao enviar arquivo")
        console.error("Erro ao enviar arquivo:", error);
      }
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    ler(){
        this.$emit('getRemessaBradescoRetorno')
    }
  },
};
</script>

<style>
</style>