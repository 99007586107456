<template>
  <div class="row">
      <div class="col-md-12">
           <h1>Confirmar Pagamento ?</h1>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>