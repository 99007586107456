<script>
export default {
  props: {
    banco: { type: Object },
    pessoas: { type: Array },
  },
  data() {
    return {
      vet_pessoas: [],
    };
  },
  created() {
    this.vet_pessoas = this.pessoas;
  },
  methods: {
    get_pessoas(id_pessoa) {
      let objeto = this.vet_pessoas.find((p) => p.id == id_pessoa);
      let resultado = objeto;
      console.log("Mostrando Resultado")
      console.log(resultado.pes_apelido)
      return resultado.pes_apelido;
    },
  },
};
</script>

<template>
  <div id="banco">
    <p>Nome: {{ banco.venda ?  get_pessoas(banco.venda.cliente_id) : banco.cliente_nome }}</p>
    <p>Tipo Pagamento: {{ banco.tipo_pagamento }}</p>
    <p>Valor: {{ banco.valor }}</p>
    <p>Numero: {{ banco.num_documento }}</p>
    <p>Quantidade de Parcelas: {{ banco.qtd_parcelas }}</p>
    <p>
      Criado:
      <b-badge
        data-name="created_at"
        class="field-created_at"
        variant="secondary"
        >{{ banco.created_at }}</b-badge
      >
    </p>
    <p>
      Última alteração:
      <b-badge
        data-name="updated_at"
        class="field-updated_at"
        variant="secondary"
        >{{ banco.updated_at }}</b-badge
      >
    </p>
  </div>
</template>
