<script>
import appConfig from "@/app.config";
import { authEasyMethods, notificationMethods } from "@/state/helpers";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list.vue";
import Insert from "./insert.vue";
import Edit from "./edit.vue";
import { http } from "../../../../helpers/easyindustriaapi/config";
import upRemessa from "./uploadRetRemessa.vue"

export default {
  page: {
    title: "Contas a Receber",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Insert, List, Edit, upRemessa },
  data() {
    return {
      showModal:false,
      currentBanco: {},
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Financeiro",
          href: "/",
          active: true,
        },
        {
          text: "Contas a Receber",
          href: "/financeiro/contasReceber",
          active: true,
        },
      ],
      //tipoMovimento: undefined,
      //tiposMovimento: [],
      banco: undefined,
      T_bancos: [],
      titleBody: "Contass a Receber",
      editando: false,
      inserindo: false,
      hideSearches: false,
      historicos: [],
      clientes: [],
      tiposPagamentos: [],
      centtros: [],
      centro_resultado: [],
      detalhe_boleto: null,
    };
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa")); // para saber a emrpessa atual;
    // storage esta no navegador em aplucation;
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    isHide() {
      return !this.inserindo && !this.editando;
    },
  },
  mounted() {
    this.getData();
    this.get_historicos();
    this.get_fornecedores();
    this.get_tipo_pagamento();
    // this.doPost(this.banco)
    this.get_centros();
    this.get_centros_resultado();
  },
  methods: {
    ...authEasyMethods,
    ...notificationMethods,
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    searchInputs() {
      this.hideSearches = !this.hideSearches;
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    voltar_ret(){
      this.showModal = false
    },
    ler_ret() {
      this.showModal = true
      console.log(
        "lendo Arquivo de retorno para Apgater as COntaas a receber que já forma quitadas"
      );
      // this.getRemessaBradescoRetorno();
    },
    
    async get_tipo_pagamento() {
      this.onLoader();
      try {
        let response = await http.get(
          "/tipopagamento?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.tiposPagamentos = response.data;
          this.offLoader();
        }
      } catch (error) {
        this.makeToast("danger", error.response);
        this.offLoader();
      }
    },
    async getData() {
      this.onLoader();

      try {
        let response = await http.get(
          "/receber?empresa_id=" + this.currentEmpresa.id
        );
        //let response = await http.get('http://localhost:8080/api/banco?empresa_id=1');
        // console.log(response1.data);
        if (response.status === 200) {
          this.T_bancos = response.data;
          console.log(this.T_bancos);
          setTimeout(() => {
            this.offLoader();
          }, 800); // tempo para carregar a tela;
        } else {
          this.T_bancos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async getRemessaBradesco(objeto) {
      try {
        let response = await http.post(
          "/Remessa/bradesco?empresa_id=" + this.currentEmpresa.id,
          {
            responseType: "blob", // Usar 'blob' para capturar o conteúdo de arquivo
          },
          objeto
        );

        if (response.status === 200) {
          const blob = new Blob([response.data], { type: "text/plain" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "C237.rem";
          link.click();
        } else {
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        this.makeToast(
          "danger",
          "Erro ao carregar o arquivo: " + error.message
        );
      }
    },
    get_res(vet_res) {
      let res_final = "";
      vet_res.forEach((item) => {
        res_final = res_final + " | " + item.nossoNumero;
      });

      return res_final;
    },
    update_ret_conta_receber(vet_ret_nao_pagas, vet_pagas){
       console.log("vetor de contas nao pagas")
       console.log(vet_ret_nao_pagas)
       console.log("vetor de contas pagas")
       console.log(vet_pagas)
       console.log("vet contas a receber")
       console.log(this.T_bancos)
       let vet_res =[]
        vet_pagas.forEach((item)=>{
          console.log(item)
          let res =this.T_bancos.find((b)=>b.num_documento == item.numeroDocumento)
          vet_res.push({...res})
        })
        console.log("Monstrando as Contas a reeber que devem ser quitadas")
        console.log(vet_res)

        this.enviado_update_contas_receber(vet_res)
    },
    enviado_update_contas_receber(vet_res){
      let vet_envia  = [...vet_res]
      console.log("mosntrando Vetor com contas a receber Quitadas Para Ataulizar")
      console.log(vet_envia)
      vet_envia.forEach((item)=>{
        console.log(item)
        let objeto = {...item}
        objeto.status = 1
        this.doPut({...objeto})
      })
    },
    async getRemessaBradescoRetorno() {
      try {
        let response = await http.get(
          "/Remessa/bradescoRetorno?empresa_id=" + this.currentEmpresa.id
          // {
          //   responseType: "blob", // Usar 'blob' para capturar o conteúdo de arquivo
          // }
        );
        if (response.status === 200) {
          // const blob = new Blob([response.data], { type: "text/plain" });
          // const link = document.createElement("a");
          // link.href = window.URL.createObjectURL(blob);
          // link.download = "remessaBradesco.txt";
          // link.click();
          // console.log(response.data)
          let vet = response.data;
          let vet2 = [...vet[0]];
          // console.log(vet2)
          let res = vet2.filter((item) => item.dataCredito === null);
          let res2 = vet2.filter((item) => item.dataCredito !== null);
          // console.log(res)
          this.makeToast("success", "Leitura Realizada");
          if (res) {
            if (res.length >= 1) {
              console.log(res);
              this.makeToast(
                "success",
                vet[1].liquidados +
                  " Boletos Foram Liquidados" +
                  "e os não liquidados foram" +
                  this.get_res(res)
              );
            } else {
              console.log(res);
              this.makeToast(
                "success",
                vet[1].liquidados + " Boletos Foram Liquidados"
              );
            }
            this.update_ret_conta_receber(res,res2)
          } else {
            this.makeToast("success", "Todos os Boletos forma Liquidados");
          }
        } else {
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        this.makeToast(
          "danger",
          "Erro ao carregar o arquivo: " + error.message
        );
      }
    },
    async getRemessaBradescoExtendida() {
      try {
        let response = await http.get(
          "/Remessa/bradescoExtendido?empresa_id=" + this.currentEmpresa.id,
          {
            responseType: "blob", // Usar 'blob' para capturar o conteúdo de arquivo
          }
        );

        if (response.status === 200) {
          const blob = new Blob([response.data], { type: "text/plain" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "remessaBradescoExtendida.txt";
          link.click();
        } else {
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        this.makeToast(
          "danger",
          "Erro ao carregar o arquivo: " + error.message
        );
      }
    },
    async getRemessaBradescoPdf(objeto) {
      try {
        let response = await http.post(
          "/Remessa/bradescoPdf?empresa_id=" + this.currentEmpresa.id,
          objeto,
          {
            responseType: "blob", // Usar 'blob' para capturar o conteúdo de arquivo
          }
        );

        if (response.status === 200) {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "remessaBradesco.pdf";
          link.click();
        } else {
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        this.makeToast(
          "danger",
          "Erro ao carregar o arquivo: " + error.message
        );
      }
    },
    async getRemessaTxt() {
      try {
        let response = await http.get(
          "/Remessa?empresa_id=" + this.currentEmpresa.id,
          {
            responseType: "blob", // Usar 'blob' para capturar o conteúdo de arquivo
          }
        );

        if (response.status === 200) {
          const blob = new Blob([response.data], { type: "text/plain" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "remessa.txt";
          link.click();
        } else {
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        this.makeToast(
          "danger",
          "Erro ao carregar o arquivo: " + error.message
        );
      }
    },
    async getRemessa() {
      try {
        let response = await http.get(
          "/Remessa/boleto?empresa_id=" + this.currentEmpresa.id,
          {
            responseType: "blob", // Defina o tipo de resposta como 'blob' para PDFs
          }
        );

        if (response.status === 200) {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "boleto.pdf";
          link.click();
        } else {
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        this.makeToast("danger", "Erro ao carregar PDF: " + error.message);
      }
    },
    async getDetalhaBoleto(objeto) {
      // this.onLoader();

      try {
        let response = await http.post(
          "/brasil/detalhaBoleto?empresa_id=" + this.currentEmpresa.id,
          objeto
        );
        //let response = await http.get('http://localhost:8080/api/banco?empresa_id=1');
        // console.log(response1.data);
        if (response.status === 200) {
          this.detalhe_boleto = response.data;
          console.log(response.data);
        } else {
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    //     async get_token() {
    //   try {
    //     const response = await http({
    //       url: "/brasil/token?empresa_id=" + this.currentEmpresa.id,
    //       method: "GET",
    //       responseType: "blob" // Isso é importante para tratar o PDF como um arquivo blob
    //     });

    //     if (response.status === 200) {
    //       // Criando um link temporário para download
    //       const blob = new Blob([response.data], { type: "application/pdf" });
    //       const link = document.createElement('a');
    //       link.href = window.URL.createObjectURL(blob);
    //       link.download = "boleto.pdf"; // Nome sugerido para o arquivo baixado
    //       link.click();

    //       // Limpando o link temporário
    //       window.URL.revokeObjectURL(link.href);
    //     } else {
    //       this.makeToast("danger", "Erro ao carregar o PDF");
    //     }
    //   } catch (error) {
    //     this.makeToast("danger", "Erro ao baixar o PDF");
    //     console.error(error);
    //   }
    // },
    async get_token() {
      try {
        const response = await http.get(
          "/brasil/token?empresa_id=" + this.currentEmpresa.id,
          {
            responseType: "blob", // Definindo o tipo de resposta como blob para receber o PDF
          }
        );

        if (response.status === 200) {
          // Criando um link temporário para download
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "boleto.pdf"; // Nome sugerido para o arquivo baixado
          link.click();

          // Limpando o link temporário
          window.URL.revokeObjectURL(link.href);
        } else {
          this.makeToast("danger", "Erro ao carregar o PDF");
        }
      } catch (error) {
        this.makeToast("danger", "Erro ao baixar o PDF");
        console.error(error);
      }
    },
    async gera_boleto_detalhado(objeto) {
      try {
        const response = await http.post(
          "/brasil/downloadDetalhaBoleto?empresa_id=" + this.currentEmpresa.id,
          objeto,
          {
            responseType: "blob", // Definindo o tipo de resposta como blob para receber o PDF
          }
        );

        if (response.status === 200) {
          // Criando um link temporário para download
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "boleto.pdf"; // Nome sugerido para o arquivo baixado
          link.click();

          // Limpando o link temporário
          window.URL.revokeObjectURL(link.href);
        } else {
          this.makeToast("danger", "Erro ao carregar o PDF");
        }
      } catch (error) {
        this.makeToast("danger", "Erro ao baixar o PDF");
        console.error(error);
      }
    },
    setBanco(banco) {
      this.currentBanco = banco;
    },
    insert() {
      this.inserindo = !this.inserindo;
    },
    edit(oldBanco) {
      this.setBanco(oldBanco);
      this.editando = !this.editando;
    },

    async doPost(receber) {
      receber.empresa_id = this.currentEmpresa.id;
      receber.user_id = this.currentUser.id;
      //console.log(receber);
      this.onLoader();

      try {
        let response = await http.post(
          "/receber?empresa_id=" + this.currentEmpresa.id,
          receber
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.getData();
            this.inserindo = false;
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async emvia_vet_remessa(objeto) {
      // receber.empresa_id = this.currentEmpresa.id;
      // receber.user_id = this.currentUser.id;
      //console.log(receber);
      // this.onLoader();

      try {
        let response = await http.post(
          "/Remessa/bradescoCnabMulti?empresa_id=" + this.currentEmpresa.id,
          objeto,
          {
            responseType: "blob", // Usar 'blob' para capturar o conteúdo de arquivo
          }
        );

        if (response.status === 200) {
          console.log("Mostrnaod o valaor  do remeessa muti");
          console.log(response.data);
          const blob = new Blob([response.data], { type: "text/plain" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "C237_multi.rem";
          link.click();
        } else {
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        this.makeToast(
          "danger",
          "Erro ao carregar o arquivo: " + error.message
        );
      }
    },
    async post_registra_boleto(receber) {
      // this.onLoader();

      try {
        let response = await http.post(
          "/brasil/registrarBoleto?empresa_id=" + this.currentEmpresa.id,
          receber
        );
        if (response) {
          // this.offLoader();
          if (response.status === 200) {
            // this.getData();
            // this.inserindo = false;
            console.log(response.data);
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async post_pagarBoleto(receber) {
      // this.onLoader();

      try {
        let response = await http.post(
          "/brasil/pagarBoleto?empresa_id=" + this.currentEmpresa.id,
          receber
        );
        if (response) {
          // this.offLoader();
          if (response.status === 200) {
            // this.getData();
            // this.inserindo = false;
            console.log("Monstrand Resultao da Baixar do Boleto");
            console.log(response.data);
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async post_pixBoleto(receber) {
      // this.onLoader();

      try {
        let response = await http.post(
          "/brasil/consultaPixBoleto?empresa_id=" + this.currentEmpresa.id,
          receber
        );
        if (response) {
          // this.offLoader();
          if (response.status === 200) {
            // this.getData();
            // this.inserindo = false;
            console.log("Monstrand Resultao da Baixar do Boleto");
            console.log(response.data);
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async post_can_pixBoleto(receber) {
      // this.onLoader();

      try {
        let response = await http.post(
          "/brasil/cancelarPixBoleto?empresa_id=" + this.currentEmpresa.id,
          receber
        );
        if (response) {
          // this.offLoader();
          if (response.status === 200) {
            // this.getData();
            // this.inserindo = false;
            console.log("Monstrand Resultao da Baixar do Boleto");
            console.log(response.data);
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async doPut(receber) {
      this.onLoader();
      receber.user_id = this.currentUser.id;
      try {
        let response = await http.put(
          "/receber/" + receber.id + "?empresa_id=" + this.currentEmpresa.id,
          receber
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.makeToast("success", "Registro alterado");
            // baixa no boleto
            // this.post_pagarBoleto(receber);
            this.getData();
            this.editando = false;
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async doDelete(receber) {
      this.onLoader();

      try {
        let response = await http.delete(
          "/receber/" + receber.id + "?empresa_id=" + this.currentEmpresa.id,
          receber
        );
        if (response) {
          if (response.status === 200) {
            this.getData();
            this.offLoader();
            this.makeToast("warning", "Registro excluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async get_historicos() {
      try {
        await http
          .get("/historicopadrao?empresa_id=" + this.currentEmpresa.id)
          .then((res) => {
            this.historicos = res.data ? res.data : null;
            // console.log(this.historicos)
          });
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
    async get_fornecedores() {
      try {
        await http
          .get("/pessoa?empresa_id=" + this.currentEmpresa.id)
          .then((res) => {
            this.clientes = res.data ? res.data : null;
            // console.log(this.fornecedores)
          });
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
    async get_centros() {
      try {
        await http
          .get("/centrocusto?empresa_id=" + this.currentEmpresa.id)
          .then((res) => {
            this.centtros = res.data ? res.data : null;
            // console.log(this.centtros)
          });
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
    async get_centros_resultado() {
      try {
        await http
          .get("/centroresultado?empresa_id=" + this.currentEmpresa.id)
          .then((res) => {
            this.centro_resultado = res.data ? res.data : null;
            //console.log(this.centtros)
          });
      } catch (error) {
        // this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
    back() {
      this.inserindo = false;
      this.editando = false;
      this.titleBody = "Contas a Receber";
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <b-modal
          :visible.sync="showModal"
          id="modal-xl"
          size="xl"
          title="Upload Retorno Remessa"
          hide-footer
          @hidden="voltar_ret()"
        >
        <upRemessa @getRemessaBradescoRetorno="getRemessaBradescoRetorno" :showModal="showModal"/>
        </b-modal>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- <div class="col-sm-12 col-md-6"></div> -->
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <button
                  v-if="isHide"
                  class="btn btn-light"
                  @click="searchInputs()"
                >
                  <span
                    class="fa-sm fa"
                    :class="hideSearches ? 'fa-chevron-up' : 'fa-chevron-down'"
                  ></span>
                  Busca avançada
                </button>
              </div>
              <div class="col-sm-12 col-md-12 text-md-right">
                <button
                  @click="insert()"
                  v-if="isHide"
                  type="button"
                  class="btn btn-success"
                >
                  + Incluir Conta
                </button>
                <button
                  @click="ler_ret()"
                  v-if="isHide"
                  type="button"
                  class="btn btn-success ml-2"
                >
                  + Ler Arquivo de Retorno
                </button>
                <button
                  @click="back()"
                  v-if="!isHide"
                  class="btn btn-secondary"
                >
                  Voltar
                </button>
              </div>
            </div>
            <div class="card-body" v-if="hideSearches">
              <!-- Start Card -->
              <div class="card border border-primary">
                <div class="card-body">
                  <h4 class="card-title">Filtros</h4>
                  <p class="card-title-desc">Informações do Cliente</p>
                  <form class="needs-validation" name="search">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="searchCompras-fromData">Cliente:</label>
                          <div class="row">
                            <div class="col-md-6">
                              <!-- type="datetime-local" -->
                              <input
                                id="codigo_id"
                                type="text"
                                class="form-control"
                                placeholder="Digite o Código"
                              />
                            </div>
                            <div class="col-md-6">
                              <input
                                id="nome_id"
                                type="text"
                                class="form-control"
                                placeholder="Digite o Nome"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3"></div>
                    </div>
                    <button class="btn btn-primary" type="button">
                      Buscar
                    </button>
                  </form>
                </div>
              </div>
              <!-- End Card -->
            </div>
          </div>
        </div>
        <div class="card">
          <div v-if="!isHide" class="card-body">
            <Edit
              v-if="editando"
              :empresa="currentEmpresa"
              :oldRecebe="currentBanco"
              @newTitle="changeHomeTitle"
              @doPut="doPut"
              :centtros="centtros"
              :centro_resultado="centro_resultado"
              :tiposPagamentos="tiposPagamentos"
              :clientes="clientes"
              :historicos="historicos"
            />
            <Insert
              v-if="inserindo"
              :centtros="centtros"
              :centro_resultado="centro_resultado"
              :tiposPagamentos="tiposPagamentos"
              :clientes="clientes"
              :historicos="historicos"
              @newTitle="changeHomeTitle"
              @doPost="doPost"
            />
          </div>
          <List
            v-if="isHide"
            :currentEmpresa="currentEmpresa"
            :listBancos="T_bancos"
            :hide="isHide"
            :pessoas="clientes"
            :detalhe_boleto="detalhe_boleto"
            @edit="edit"
            @newTitle="changeHomeTitle"
            @setBanco="setBanco"
            @doDelete="doDelete"
            @doPut="doPut"
            @get_token="get_token"
            @getDetalhaBoleto="getDetalhaBoleto"
            @gera_boleto_detalhado="gera_boleto_detalhado"
            @post_registra_boleto="post_registra_boleto"
            @post_pagarBoleto="post_pagarBoleto"
            @post_pixBoleto="post_pixBoleto"
            @post_can_pixBoleto="post_can_pixBoleto"
            @getRemessa="getRemessa"
            @getRemessaTxt="getRemessaTxt"
            @getRemessaBradesco="getRemessaBradesco"
            @getRemessaBradescoPdf="getRemessaBradescoPdf"
            @getRemessaBradescoExtendida="getRemessaBradescoExtendida"
            @getRemessaBradescoRetorno="getRemessaBradescoRetorno"
            @emvia_vet_remessa="emvia_vet_remessa"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>