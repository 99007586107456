<script>
import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";

export default {
  props: {
    oldRecebe: { type: Object, required: true },
    historicos: { type: Array },
    clientes: { type: Array },
    tiposPagamentos: { tyep: Array },
    centtros: { type: Array },
    centro_resultado: { type: Array }
  },
  components: { Multiselect },
  data() {
    return {
      loader: { get: false },
      titleBody: 'Alterar Banco',
      banco: {},
      contaR:{},
      show: false,
      get_historicos: null,
      get_clientes: null,
      tiposPagamentos1: null,
      centros: null,
      resultados: null,
      verifica: false,
      valor:null,
      historico:null,
      cliente:null,
      debito:null,
      credito:null,
      pagamento:null,
    }
  },
  validations: {
    banco: {
      banco_nome: { required },
      banco_codigo: { required },
    }
  },
  created() {
    this.get_historicos = this.historicos
    this.get_clientes = this.clientes
    this.tiposPagamentos1 = this.tiposPagamentos
    this.centros = this.centtros
    this.resultados = this.centro_resultado
    this.banco =  { ...this.oldRecebe },
    console.log(this.banco)
  },
  mounted() {
    this.setNewTitle();
    // cria um clone para evitar referenias
    // se nãp fizer isso da um bug quando editar um valor e volta para index 
    // o valor do inde é alterado mesmo sem fazer o put   
    // por isso precisamos clonar o props OldReceber 
    this.valor_real();
    this.historicos_verifica();
    this.cliente_verifica();
    this.pagamento_verifica();
    this.credito_verifica();
    this.debito_verifica();
    console.log("Objeto Recebe Conta")
    console.log(this.oldRecebe)
    this.preencher_vlr_edit()
  },
  computed:{
     
  },
  methods: {
    seleciona_pagamento(event){
       console.log(event)
       this.pagamento = event
       this.contaR.tipo_pagamento = event.id
    },
    seleciona_cliente(event){
       console.log(event)
       this.cliente = event
       this.contaR.cliente_cod = event.id
       this.contaR.cliente_nome = event.pes_apelido
      // cliente_nome
      //  this.contaR.tipo_pagamento = event.id
    },
    preencher_vlr_edit(){
      console.log(this.oldRecebe)
      this.contaR = this.oldRecebe
      this.contaR.emissao = this.oldRecebe.emissao + 'T00:00:00'
      this.contaR.valor = this.oldRecebe.venda == null ?  this.oldRecebe.valor : this.oldRecebe.venda.vlr_nfe
      this.contaR.num_documento =  this.oldRecebe.venda != null ?  this.oldRecebe.n_nfe : this.oldRecebe.num_documento
      this.contaR.qtd_parcelas = this.oldRecebe.qtd_parcelas
      this.contaR.cod_cupom = this.oldRecebe.cod_cupom
      this.contaR.parcela = this.oldRecebe.parcela + 'T00:00:00'
      this.contaR.fatura = this.oldRecebe.fatura + 'T00:00:00'
      this.contaR.obs= this.oldRecebe.obs
      
    },
    historicos_verifica(){
      //console.log(this.banco.historico_cod)
      const historicoSelecionado = this.get_historicos.find((h) => h.id === this.banco.historico_cod);
      console.log(historicoSelecionado)
      this.historico = historicoSelecionado
    },
    cliente_verifica(){
      //console.log(this.banco.historico_cod)
      const clienteSelecionado = this.get_clientes.find((c) => c.id === this.banco.cliente_cod);
      console.log(clienteSelecionado)
      this.cliente = clienteSelecionado
    },
    debito_verifica(){
      //console.log(this.banco.historico_cod)
      const debitoSelecionado = this.centros.find((c) => c.id === this.banco.debito);
      console.log(debitoSelecionado)
      this.debito = debitoSelecionado
    },
    credito_verifica(){
      console.log(this.banco.credito)
      const creditoSelecionado = this.resultados.find((r) => r.id === this.banco.credito);
      console.log(creditoSelecionado)
      this.credito = creditoSelecionado
    },
    pagamento_verifica(){
      //console.log(this.banco.historico_cod)
      const pagamentoSelecionado = this.tiposPagamentos1.find((p) => p.id === this.banco.tipo_pagamento);
      console.log(pagamentoSelecionado)
      this.pagamento = pagamentoSelecionado
      this.contaR.tipo_pagamento = pagamentoSelecionado.id
    },
    valor_real(){
     let valor = parseFloat(this.banco.valor)
     const formatoMoeda = {
        style: "currency",
        currency: "BRL"
      };
      const valorFormatado = valor.toLocaleString("pt-BR", formatoMoeda);
      this.banco.valor = valorFormatado.replace("R$", " ");
    },  
   
    customLabelComposicao({ id, identificacao }) {
      return `${id} – ${identificacao}`
    },
    customLabelCliente({ id, pes_fantasia }) {
      return `${id} – ${pes_fantasia}`
    },
    customLabelHistorico({ id, descricao }) {
      return `${id} – ${descricao}`
    },
    customLabelPagamento({ id, tiponome }) {
      return `${id} – ${tiponome}`
    },
    abrir_modal() {
      this.show = true;
    },
    fechar_modal() {
      this.show = false;
      console.log(this.show)
    },
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      await this.$v.$touch();

      this.validatesFields()
        .then(() => {
          this.setPut();
        });
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
  
    converte_valor(){
      let stringValor = this.banco.valor
      let valorFlutuante = parseFloat(stringValor.replace("R$", " ").replace(/\./g, "").replace(",", "."));
      console.log(valorFlutuante)
      this.banco.valor = valorFlutuante
    },
    setPut() {
      // this.converte_valor()
      console.log(this.contaR)
      console.log('Gravou');
      console.log(this.contaR)
       this.$emit('doPut', this.contaR);
    },
    formatInput_valor_info() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.banco.valor.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.banco.valor = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).replace("R$", " ");
    },
  },

}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <i v-if="loader.get" class="fa fa-spinner fa-5x fa-spin text-success text-center"></i>
      <form id="form-tipo-movimento" role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-body>
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.recebe1 variant="light">Contas a Receber:</b-button>
            </b-card-header>
            <b-collapse id="recebe1" visible accordion="pesquisar" role="tabpanel">
              <div class="form-row form-group" role="group">
                <!-- <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="tipo_nome_id" class="col-form-label">historico Padrão</label>
                    <div class="col">
                      <multiselect label="id" :options="get_historicos" v-model="historicos_cod"
                        @select="select_hist_nome($event)">
                      </multiselect>
                      
                    </div>
                  </div>
                </div> -->
                <!-- <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_nome_id" class="col-form-label">historico Padrão</label>
                    <div class="col">
                      <multiselect label="descricao" :options="get_historicos" v-model="historicos_1"
                        @select="select_hist_cod($event)">
                      </multiselect>
                      
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="form-row form-group" role="group">
                <!-- <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="codigo" class="col-form-label">Cliente:</label>
                    <div class="col">
                      <multiselect label="id" :options="get_clientes" v-model="cliente_cod"
                        @select="select_cli_cod($event)">
                      </multiselect>
                    </div>
                  </div>
                </div> -->
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_nome_id" class="col-form-label">historico Padrão</label>
                    <div class="col">
                      <!-- <multiselect label="descricao" :options="get_historicos" v-model="historicos_1"
                        @select="select_hist_cod($event)">
                      </multiselect> -->
                      <!-- <input v-model="receber.historico" class="form-control text-leftt" type="text"
                        placeholder="digite a descrição" id="banco"> -->
                      <multiselect v-model="historico" placeholder="Selecione" label="descricao" track-by="descricao"
                        :options="get_historicos" :option-height="104" :custom-label="customLabelHistorico"
                        :show-labels="false" deselect-label="ENTER para remover" select-label="Pressione ENTER">
                        <template slot="singleLabel" slot-scope="props">
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.descricao }}</span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.descricao }}</span>
                          </div>
                        </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_codigo" class="col-form-label">Cliente:</label>
                    <div class="col">
                      <!-- <multiselect label="pes_fantasia" :options="get_clientes" v-model="cliente_cod"
                        @select="select_cli_nome($event)">
                      </multiselect> -->
                      <!-- <input v-model="receber.cliente" class="form-control text-left" type="text"
                        placeholder="digite o nome do cliente" id="codigo"> -->
                      <multiselect v-model="cliente" placeholder="Selecione" label="pes_fantasia" track-by="pes_fantasia"
                        :options="get_clientes" :option-height="104" :custom-label="customLabelCliente"
                        :show-labels="false" deselect-label="ENTER para remover" select-label="Pressione ENTER"
                        @select="seleciona_cliente($event)">
                        <template slot="singleLabel" slot-scope="props">
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.pes_fantasia }}</span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.pes_fantasia }}</span>
                          </div>
                        </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_num" class="col-form-label">Débito:</label>
                    <div class="col">
                      <!-- <Multiselect v-model="receber2.debito" :options="centros" label="identificacao">
                      </Multiselect> -->
                      <multiselect v-model="debito" placeholder="Selecione" label="identificacao" track-by="identificacao"
                        :options="centros" :option-height="104" :custom-label="customLabelComposicao" :show-labels="false"
                        deselect-label="ENTER para remover" select-label="Pressione ENTER">
                        <template slot="singleLabel" slot-scope="props">
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.identificacao }}</span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.identificacao }}</span>
                          </div>
                        </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_num" class="col-form-label">Credito:</label>
                    <div class="col">
                      <!-- // -->
                      <!-- <Multiselect v-model="receber2.credito" :options="resultados" label="identificacao">
                      </Multiselect> -->
                      <multiselect v-model="credito" placeholder="Selecione" label="identificacao" track-by="identificacao"
                        :options="resultados" :option-height="104" :custom-label="customLabelComposicao"
                        :show-labels="false" deselect-label="ENTER para remover" select-label="Pressione ENTER">
                        <template slot="singleLabel" slot-scope="props">
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.identificacao }}</span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.identificacao }}</span>
                          </div>
                        </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_digito" class="col-form-label">Forma de pagamento</label>
                    <div class="col">
                      <!-- <Multiselect v-model="receber.pagamento" :options="tiposPagamentos1" label="tiponome">
                      </Multiselect> -->
                      <multiselect v-model="pagamento" placeholder="Selecione" label="tiponome" track-by="tiponome"
                        :options="tiposPagamentos1" :option-height="104" :custom-label="customLabelPagamento"
                        :show-labels="false" deselect-label="ENTER para remover" select-label="Pressione ENTER"
                        @select="seleciona_pagamento($event)">
                        <template slot="singleLabel" slot-scope="props">
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.tiponome }}</span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">{{ props.option.id }} - {{
                              props.option.tiponome }}</span>
                          </div>
                        </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_digito" class="col-form-label">Número do Documento</label>
                    <div class="col">
                      <input v-model="contaR.num_documento" class="form-control text-left" type="text"
                        placeholder="digite o número do documento" id="codigo">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_digito" class="col-form-label">Valor</label>
                    <div class="col">
                      <input v-model="contaR.valor"  class="form-control text-left" type="text" placeholder="Digite o Valor"
                        id="codigo"  @input="formatInput_valor_info()">
                        
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_digito" class="col-form-label">Quantidade de Parcelas</label>
                    <div class="col">
                      <!-- max="5" -->
                      <input v-model="contaR.qtd_parcelas" min="1" class="form-control text-left" type="number"
                        placeholder="digite a Quantidade de Parcelas" id="codigo">
                    </div>
                  </div>
                </div>

              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_num" class="col-form-label">Emissão:</label>
                    <div class="col">
                      <input v-model="contaR.emissao" id="searchCompras-fromData" type="datetime-local"
                        class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_num" class="col-form-label">Data da Primeira Parcela:</label>
                    <div class="col">
                      <input v-model="contaR.parcela" id="searchCompras-fromData" type="datetime-local"
                        class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_num" class="col-form-label">Data da fatura:</label>
                    <div class="col">
                      <input v-model="contaR.fatura" id="searchCompras-fromData" type="datetime-local" class="form-control"
                        placeholder="dd/mm/aaaa hh:mm" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="tipo_banco_num" class="col-form-label">Código Cupom:</label>
                    <div class="col">
                      <input v-model="contaR.cod_cupom" id="searchCompras-fromData" type="text" class="form-control"
                        placeholder="Digite o Código" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-12">
                  <div class="form-group">
                    <label for="tipo_banco_orient" class="col-form-label">Observações</label>
                    <div class="col">
                      <textarea v-model="contaR.obs" name="" id="" cols="30" rows="10" class="form-control">

                    </textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-12">
                  <div class="form-group">
                    <label for="tipo_banco_orient" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <div v-if="verifica">
                        <b-table striped hover :items="contas_recebe_add" responsive id="contas-receber"
                          :fields="fields1">
                          <template #cell(excluir)="row">
                            <button @click.prevent="remove(row.item)" class="btn btn-danger">Excluir</button>
                          </template>
                        </b-table>
                      </div>
                      <button class="btn btn-secondary mr-2" @click.prevent="add_contas()">Incluir</button>
                      <button class="btn btn-secondary " @click.prevent="remove()">Excluir</button>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
            <div class="accordion" role="tablist">
              <form role="form" class="form-horizontal">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.pesquisar variant="light">Parcelas:</b-button>
                  </b-card-header>
                  <!-- visible -->
                  <b-collapse id="pesquisar" accordion="pesquisar" role="tabpanel">
                    <b-card-body>
                      <label for="cartao_in_id" class="col-form-label">Parcelas:</label>
                      <div class="col">
                        <div v-if="verifica">
                          <b-table striped hover :items="contas_receber" responsive id="contas-receber" :fields="fields">
                          </b-table>
                        </div>
                        <b-table-simple responsive>
                          <b-thead id="b-head">
                            <b-tr>
                              <b-th class="b-th">Código</b-th>
                              <b-th class="b-th">>Historico</b-th>
                              <b-th class="b-th">Vencimento</b-th>
                              <b-th class="b-th">Pagamento</b-th>
                              <b-th class="b-th">Valor</b-th>
                              <b-th class="b-th">Juros</b-th>
                              <b-th class="b-th">Pago</b-th>
                              <b-th class="b-th">Resta</b-th>
                              <b-th class="b-th">Emitido</b-th>
                              <b-th class="b-th">Quitado</b-th>
                              <b-th class="b-th">&nbsp;</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr>
                              <b-td>
                                <input id="espaco" type="text" class="form-control text-left">
                              </b-td>
                              <b-td>
                                <input id="espaco" type="text" class="form-control text-left">
                              </b-td>
                              <b-td id="espaco">
                                <input id="espaco" type="text" class="form-control text-left">
                              </b-td>
                              <b-td>
                                <div class="custom-multiselect-wrapper">
                                  <Multiselect selectLabel="" :options="tiposPagamentos1" label="tiponome">

                                  </Multiselect>
                                </div>
                              </b-td>
                              <b-td>
                                <input id="espaco" type="text" class="form-control text-right"
                                  @input="formatInput_valor_info()">
                              </b-td>
                              <b-td>
                                <input @input="formatInput_juros_parcelas()" id="espaco" type="text"
                                  class="form-control text-right">
                              </b-td>
                              <b-td>
                                <input @input="formatInput_pago_parcelas()" id="espaco" type="text"
                                  class="form-control text-right">
                              </b-td>
                              <b-td>
                                <input @input="formatInput_resta_parcelas()" id="espaco" type="text"
                                  class="form-control text-right">
                              </b-td>
                              <b-td>
                                <input id="espaco" type="text" class="form-control text-right">
                              </b-td>
                              <b-td>
                                <input id="espaco" type="text" class="form-control text-right">
                              </b-td>
                              <b-td>
                                <button class="btn btn-secondary" @click="add_parcelas($event)">Incluir</button>
                              </b-td>
                              <b-td>
                                <button class="btn btn-secondary" @click="remove($event)">Excluir</button>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                    </b-card-body>
                  </b-collapse>
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.pesquisar1 variant="light">Plano de Contas:</b-button>
                  </b-card-header>
                  <!-- visible -->
                  <b-collapse id="pesquisar1" accordion="pesquisar" role="tabpanel">
                    <b-card-body>
                      <div class="form-row form-group" role="group">
                        <div class="col-sm-12 col-md-12" id="desc_ID">
                          <div class="form-group">
                            <label for="cartao_in_id" class="col-form-label">Plano:</label>
                            <div class="col">
                              <b-table-simple responsive>
                                <b-thead id="b-head">
                                  <b-tr>
                                    <b-th class="b-th">Código</b-th>
                                    <b-th class="b-th">C.C ID</b-th>
                                    <b-th class="b-th">Débito</b-th>
                                    <b-th class="b-th">C.R Id</b-th>
                                    <b-th class="b-th">Crédito</b-th>
                                    <b-th class="b-th">Valor</b-th>
                                    <b-th class="b-th">Ativo</b-th>
                                    <b-th class="b-th">&nbsp;</b-th>
                                  </b-tr>
                                </b-thead>
                                <b-tbody>
                                  <b-tr>
                                    <b-td>
                                      <input id="espaco" type="text" class="form-control text-left">
                                    </b-td>
                                    <b-td>
                                      <input id="espaco" type="text" class="form-control text-left">
                                    </b-td>
                                    <b-td>
                                      <!-- <div class="custom-multiselect-wrapper">
                                        <multiselect v-model="plano_contas.debito" :options="centros" label="identificacao">
                                        </multiselect>
                                      </div> -->
                                      <div class="custom-multiselect-wrapper">
                                        <!-- <multiselect :options="res" label="identificacao">
                                        </multiselect> -->
                                        <multiselect placeholder="Selecione" label="identificacao"
                                          track-by="identificacao" :options="centros" :option-height="104"
                                          :custom-label="customLabelComposicao" :show-labels="false"
                                          deselect-label="ENTER para remover" select-label="Pressione ENTER">
                                          <template slot="singleLabel" slot-scope="props">
                                            <span class="option__desc">
                                              <span class="option__title">{{ props.option.id }} - {{
                                                props.option.identificacao }}</span>
                                            </span>
                                          </template>
                                          <template slot="option" slot-scope="props">
                                            <div class="option__desc">
                                              <span class="option__title">{{ props.option.id }} - {{
                                                props.option.identificacao }}</span>
                                            </div>
                                          </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                                        </multiselect>
                                      </div>
                                    </b-td>
                                    <b-td>
                                      <input id="espaco" type="text" class="form-control text-right">
                                    </b-td>
                                    <b-td>
                                      <div class="custom-multiselect-wrapper">
                                        <!-- <multiselect v-model="plano_contas.credito" :options="resultados" label="identificacao">
                                        </multiselect> -->
                                        <multiselect placeholder="Selecione" label="identificacao"
                                          track-by="identificacao" :options="resultados" :option-height="104"
                                          :custom-label="customLabelComposicao" :show-labels="false"
                                          deselect-label="ENTER para remover" select-label="Pressione ENTER">
                                          <template slot="singleLabel" slot-scope="props">
                                            <span class="option__desc">
                                              <span class="option__title">{{ props.option.id }} - {{
                                                props.option.identificacao }}</span>
                                            </span>
                                          </template>
                                          <template slot="option" slot-scope="props">
                                            <div class="option__desc">
                                              <span class="option__title">{{ props.option.id }} - {{
                                                props.option.identificacao }}</span>
                                            </div>
                                          </template><span slot="noResult">Opa! Nenhum registro encontrado</span>
                                        </multiselect>
                                      </div>
                                    </b-td>
                                    <b-td>
                                      <input id="espaco" type="text" class="form-control text-right"
                                        @input="formatInput_valor_plano_contas()">
                                    </b-td>
                                    <b-td>
                                      <input id="espaco" type="text" class="form-control text-right">
                                    </b-td>
                                    <b-td>
                                      <button class="btn btn-secondary">Incluir</button>
                                    </b-td>
                                  </b-tr>
                                </b-tbody>
                              </b-table-simple>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </form>
            </div>
          </b-card-body>
        </b-card>
      </form>
      <div class="card-body" v-if="show">
        <baixa @fechar_modal="fechar_modal" :tiposPagamentos1="tiposPagamentos1" :show="show" />
      </div>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light" @click.prevent="setPut()">Gravar</button>
        <button class="btn btn-light ml-4" @click="abrir_modal()">Baixar</button>
      </div>
    </div>
  </div>
</template>